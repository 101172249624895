$primary: white;
$secondary: #8b1619;
$dark: #393939;
$light: #969696;
$black: black;


$white: white;
$red: #ff0000;
$green: #00CBA4;
$yellow: #ffd437;

$theme-colors: (
		"yellow": $yellow,
		"green": $green,
		"white": $white,
        "black": $black,
        "red": $red
);

$link-hover-color: $green;
$btn-close-color: $white;
$btn-close-opacity: 1;
$navbar-dark-color: $white;
$navbar-dark-toggler-border-color: transparent;
$btn-close-width: 1.6rem;
$btn-close-height: 1.6rem;
$btn-close-padding-y: 0;
$btn-close-padding-x: 0;

$dropdown-link-hover-color:         $green;
$dropdown-link-hover-bg:            transparent;
$dropdown-link-active-color: 		$green;
$dropdown-link-active-bg: 			transparent;

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow: .2rem .25rem 0.25rem rgba($black, .25) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

$body-bg: $black;
$body-color: $primary;

$border-radius: 0;

//MODAL
$modal-content-color: $white;
$modal-content-bg: $black;
$modal-content-border-radius: 0;
$modal-header-border-width: 0;

$font-size-base: 1rem;

//$h1-font-size: $font-size-base * 1.75;// * 2.5;
//$h2-font-size: $font-size-base * 2.5; //* 1.75;
//$h3-font-size: $font-size-base * 1.35;
//$h4-font-size: $font-size-base * 1.25;
//$h5-font-size: $font-size-base * 1.1;
//$h6-font-size: $font-size-base;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$headings-font-weight: $font-weight-light;

$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$headings-font-family: 'Roboto', sans-serif;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1540px
);

$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * 0.25,
        2: $spacer * 0.5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: ($spacer * 6),
        7: ($spacer * 7),
        8: ($spacer * 8),
);
