aside#main-navigation {
	position: fixed;
	right: 16px;
	top: 16px;
	z-index: 111;

	&.active {
		button#nav-toggler {
			.hamburger-line {
				&:nth-child(1){
					top: 50%;
					transform: rotate(45deg);
				}
				&:nth-child(2){
					opacity: 0;
				}
				&:nth-child(3){
					top: 50%;
					transform: rotate(-45deg);
				}
			}
		}

		nav#menu {
			display: flex;
			background-color: $black;
		}
	}
}

button#nav-toggler {
	position: relative;
	appearance: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	width: 32px;
	height: 24px;
	margin-left: auto;
	display: block;
}

.hamburger-line {
	position: absolute;
	background-color: $white;
	width: 100%;
	height: 3px;
	left: 0;
	transition: all 0.1s ease-in-out;
	&:nth-child(1){
		top: 0;
	}
	&:nth-child(2){
		top: 50%;
		transform: translateY(-50%);
	}
	&:nth-child(3){
		top: 100%;
		transform: translateY(-100%);
	}
}



nav#menu {
	display: none;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	margin-top: 16px;

	a {
		text-decoration: none;
		border-bottom: solid 1px $green;
	}
}

.nav-item {
	font-size: 10px;
	font-weight: bold;
	padding: 8px;
	border-radius: 4px;
	text-decoration: none;
}
