section[data-anchor="intro"] {
	.logo {
		width: 280px;
		max-width: 100%;
	}

	h1 {
		text-transform: uppercase;
		color: $green;
	}

	.container {
		display: flex;
		flex-direction: column;
		gap: 64px;
		align-items: center;
	}
}

section[data-anchor="about"] {
	h2 {
		margin-bottom: 3em;

		@include media-breakpoint-down(md){
			margin-bottom: 1em;
		}
	}
}

section[data-anchor="technology"] {
	.section-content {
		gap: 64px;

		@include media-breakpoint-down(md){
			gap: 32px;
		}
	}

}

section[data-anchor="saturnin"] {
	.logo {
		width: 240px;
		max-width: 100%;
	}

	.container {
		display: flex;
		flex-direction: column;
		gap: 64px;
		align-items: center;
		@include media-breakpoint-down(md){
			gap: 32px;
		}
	}

}

section[data-anchor="career"] {
	h2 {
		margin-bottom: 64px;
	}

	.career-list {
		display: grid;
		gap: 16px;
		width: fit-content;
		max-width: 100%;
		margin: 0 auto;
	}
}

section[data-anchor="contacts"] {
	.container {
		display: flex;
		flex-direction: column;
		gap: 64px;
		align-items: center;
		@include media-breakpoint-down(md){
			gap: 32px;
		}
	}

	.contact-options {
		display: flex;
		gap: 32px;
		justify-content: center;

		@include media-breakpoint-down(sm){
			flex-direction: column;
		}
	}

	.contact-options-item {
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: center;
		text-decoration: none;

		img {
			width: 36px;
		}
	}
}
