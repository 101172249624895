html {
	height: 100vh;
	overflow: hidden;
}

.fullpage {
	transition: transform .35s ease;
	.fullpage-section {
		height: 100vh !important;
	}
}

html.fp-enabled {
	margin: 0;
	padding: 0;
	overflow: hidden;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.fp-enabled {
	body {
		margin: 0;
		padding: 0;
		overflow: hidden;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
}
.fp-section {
	position: relative;
	box-sizing: border-box;
}
.fp-slide {
	float: left;
	height: 100%;
	display: block;
}
.fp-slidesContainer {
	height: 100%;
	display: block;
	float: left;
	position: relative;
}
.fp-slides {
	z-index: 1;
	height: 100%;
	overflow: hidden;
	//position: relative;
	transition: all 0.3s ease-out;
}
.fp-section.fp-table {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.fp-slide.fp-table {
	//display: table;
	//table-layout: fixed;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
}
.fp-tableCell {
	//display: table-cell;
	//vertical-align: middle;
	width: 100%;
	height: 100%;
	position: relative;
}

.fp-controlArrow {
	user-select: none;
	position: absolute;
	z-index: 5;
	bottom: 0;
	cursor: pointer;
	transform: translateY(-50%);
	&.fp-prev {
		transform-origin: right bottom;
		@extend %arrow, %arrow-left;
		left: 7px;
		margin-left: 10vw;
		@include media-breakpoint-up(sm){
			margin-left: 30vw;
		}
	}
	&.fp-next {
		@extend %arrow, %arrow-right;
		right: 7px;
		margin-right: 10vw;
		@include media-breakpoint-up(sm){
			margin-right: 30vw;
		}
	}
}


.fp-scrollable {
	overflow: hidden;
	position: relative;
}
.fp-scroller {
	overflow: hidden;
}
.iScrollIndicator {
	border: 0 !important;
}
.fp-notransition {
	-webkit-transition: none !important;
	transition: none !important;
}
@mixin nav($direction){
	ul {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: $direction;
		gap: .25rem;
		li {
			margin: 0;
			padding: 0;
			position: relative;
			display: flex;
			a {
				padding: 5px;
				@include media-breakpoint-up(sm){
					padding: 7px;
				}
				display: block;
				position: relative;
				z-index: 1;
				//width: 100%;
				//height: 100%;
				cursor: pointer;
				text-decoration: none;
				&:before {
					content: '';
					display: block;
					border-radius: 50%;
					z-index: 1;
					height: 9px;
					width: 9px;
					@include media-breakpoint-up(sm){
						height: 12px;
						width: 12px;
					}
					border: 0;
					background: white;
					transition: all 0.35s ease-in-out;
					opacity: .9;
				}
				span:nth-child(2){
					display: none;
				}
				&.active {
					&:before {
						background-color: $green;
					}
				}
				&:hover {
					&:before {
						opacity: 1;
					}
				}
			}

		}
	}
}
#fp-nav {
	position: fixed;
	z-index: 100;
	top: 1rem;
	opacity: 1;
	@include nav(column);
	ul li{
		&:hover {
			.fp-tooltip {
				-webkit-transition: opacity 0.2s ease-in;
				transition: opacity 0.2s ease-in;
				width: auto;
				opacity: 1;
			}
		}
		.fp-tooltip {
			position: absolute;
			top: -2px;
			color: #fff;
			font-size: 14px;
			font-family: arial, helvetica, sans-serif;
			white-space: nowrap;
			max-width: 220px;
			overflow: hidden;
			display: block;
			opacity: 0;
			width: 0;
			cursor: pointer;
		}
		.fp-tooltip.fp-right {
			right: 20px;
		}
		.fp-tooltip.fp-left {
			left: 20px;
		}
	}
}
#fp-nav.fp-right {
	right:  1rem;
	top: 50%;
	transform: translateY(-50%);
	justify-content: center;
	display: flex;
	flex-direction: column;
	@include media-breakpoint-down(md){
		right: 0.5rem;
	}
}
#fp-nav.fp-left {
	left:  1rem;
}
.fp-slidesNav {
	display: flex;
	justify-content: center;
	position: absolute;
	z-index: 4;
	opacity: 1;
	transform: translate3d(0,0,0);
	left: 0 !important;
	right: 0;
	margin: 0 auto !important;
	@include nav(row);
}
.fp-slidesNav.fp-bottom {
	bottom: 17px;
}
.fp-slidesNav.fp-top {
	top: 17px;
}
#fp-nav.fp-show-active {
	a.active {
		+ {
			.fp-tooltip {
				-webkit-transition: opacity 0.2s ease-in;
				transition: opacity 0.2s ease-in;
				width: auto;
				opacity: 1;
			}
		}
	}
}
.fp-auto-height.fp-section {
	height: auto !important;
}
.fp-auto-height {
	.fp-slide {
		height: auto !important;
	}
	.fp-tableCell {
		height: auto !important;
	}
}
.fp-responsive {
	.fp-auto-height-responsive.fp-section {
		height: auto !important;
	}
	.fp-auto-height-responsive {
		.fp-slide {
			height: auto !important;
		}
		.fp-tableCell {
			height: auto !important;
		}
	}
}
.fp-sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
.fp-slidesContainer{
	width: 100% !important;
	transform: none !important;
	.fp-slide{
		transition: opacity 1s ease;
		position: absolute !important;
		width: 100% !important;
		height: 100vh;
		&:not(.active){
			opacity: 0;
		}
	}
}


//FADE EFFECT
/*
.fullpage-wrapper {
	width: 100%!important;
	transform: none!important;

	.fp-section {

		width: 100%!important;
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		z-index: 0;
		transition: opacity 1s linear;

		&.active {
			visibility: visible;
			opacity: 1;
			z-index: 1;
		}

	}
}
*/
