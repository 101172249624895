body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	min-width: 320px;
	font-size: 20px;

	@include media-breakpoint-down(md){
		font-size: 16px;
	}
}

a{
	transition: color .35s ease;
}

aside{
	z-index: 1040;
}

p {
	line-height: 1.5em;
	margin: 0;
	@include media-breakpoint-down(sm){
		line-height: 1.25em;
	}
}

p + p {
	margin-top: 0.5em;
}

.textstyle-h1,
.textstyle-h2,
.textstyle-h3 {
	color: $green;
	text-transform: uppercase;
	font-weight: 400;
}

.textstyle-h1 {
	font-size: 40px;

	@include media-breakpoint-down(md){
		font-size: 32px;
	}
}

.textstyle-h2 {
	font-size: 32px;

	@include media-breakpoint-down(md){
		font-size: 26px;
	}
}

.textstyle-h3 {
	font-size: 24px;

	@include media-breakpoint-down(md){
		font-size: 20px;
	}
}

.nav-main {
	a {
		text-decoration: none;
	}
}

.drop-shadow {
	filter: drop-shadow(2px 4px 6px black);
}

.section-content {
	text-align: center;
	position: absolute;
	z-index: 3;
	left: 0;
	top: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	padding: 64px 0;
	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(md){
		padding: 64px 0 32px 0;
	}
}

.container {
	@include media-breakpoint-down(md){
		padding: 0 40px;
	}
}

.fixed-logo {
	position: fixed;
	left: 64px;
	top: 50px;
	z-index: 100;
	width: 128px;
	transition: opacity .35s ease;
	transition-delay: .5s;

	@include media-breakpoint-down(md){
		width: 120px;
		left: 16px;
		top: 16px;
	}

	@include media-breakpoint-down(sm){
		width: 90px;
		top: 8px;
	}

	.logo {
		opacity: .65;
		width: 120px;
		max-width: 10vmax;
		min-width: 100px;
	}

	&:not(.shown) {
		opacity: 0;
		transition-delay: .5s;
	}
	svg{
		max-width: 100%;
		height: auto;
	}
}

%arrow {
	&:before {
		content: '';
		border: solid white;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 10px;
	}

	&-right:before {
		transform: rotate(-45deg);
	}

	&-left:before {
		transform: rotate(135deg);
	}

	&-up:before {
		transform: rotate(-135deg);
	}

	&-down:before {
		transform: rotate(45deg);
	}
}

.fp-bg {
	background-size: cover;
	background-position: center 80%;
}

.bg-image {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
	pointer-events: none;
}

.button {
	display: inline-block;
	color: $white;
	background-color: $green;
	border-radius: 0.5em;
	border: none;
	padding: 1em 3em;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: bold;
	font-size: 1.2rem;
	transition: ease-in-out 0.2s;
	&:hover{
		color: $green;
		background-color: $white;
	}

	@include media-breakpoint-down(md){
		font-size: 1rem;
	}
}

.cookie-settings-button {
	appearance: none;
	font-size: 14px;
	border: none;
	background: none;
	color: white;
	&:hover {
		text-decoration: underline;
	}
}
